<template>
  <div style="height: 100vh">
    <v-container fluid style="margin: 60px">
      <v-row align="center" justify="center">
        <v-col cols="12" md="6">
          <v-card elevation="12" class="mx-auto" width="70%" shaped>
            <v-card-title>
              <span class="ml-6">{{ $t("dashboardWelcomeMsg") }}</span>
            </v-card-title>
            <v-card-text>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="pa-2 subheadline mb-1">
                    <span>{{ $t("dashboardMsgLine1") }}</span>
                  </v-list-item-title>
                  <v-list-item-title class="pa-2 subheadline mb-1">
                    <span>{{ $t("homePageMsgLine2") }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-img
            :src="require('@/assets/DatabaseHome.png')"
            position="center"
            width="500"
            contain
            height="400"
            class="ma-0 pa-0 mb-n3"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      pageTitle: 'app.pageTitle.dashbaordHome',
    };
  },

  mounted() {
    console.log('-------------- Dashboard home CREATED ---------------');
    this.setPageTitleOfThisPage();
  },

  methods: {
    ...mapActions(['setPageTitle']),

    /**
     * Set page title of this page.
     */
    setPageTitleOfThisPage() {
      console.log(
        '--------------dashboard home page tityle METHOD setPageTitleOfThisPage ---------------',
      );
      this.setPageTitle(this.pageTitle);
    },
  },
};
</script>
<style scoped>
</style>
